var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let WagonWheelPropsMixin = class WagonWheelPropsMixin extends Vue {
    activityId;
    // part of Item Master primary key (see drawingNumber and side)
    asBuiltNumber;
    changeSetId;
    changeSetAuthorityId;
    cosmicEventId;
    deliveryMatrixItemId;
    documentId;
    // Item Drawing primary key
    // part of Item Master primary key (see asBuiltNumber and side)
    drawingNumber;
    hardwareListId;
    itemInstanceId;
    organizationCode;
    physicalInventoryAuditId;
    physicalInventoryAuditItemId;
    riskId;
    riskMitigationId;
    // part of Item Master primary key (see asBuiltNumber and drawingNumber)
    side;
    transactionId;
    workOrderStepId;
    get hasAtLeastOnePrimaryKey() {
        return (this.activityId ||
            this.changeSetId ||
            this.changeSetAuthorityId ||
            this.cosmicEventId ||
            this.deliveryMatrixItemId ||
            this.documentId ||
            this.hardwareListId ||
            this.itemInstanceId ||
            this.organizationCode ||
            this.physicalInventoryAuditId ||
            this.physicalInventoryAuditItemId ||
            this.riskId ||
            this.riskMitigationId ||
            this.transactionId ||
            this.workOrderStepId ||
            (this.drawingNumber && !this.asBuiltNumber && !this.side) ||
            (this.drawingNumber && this.asBuiltNumber && this.side));
    }
    getRelationshipsObj() {
        if (this.activityId) {
            return {
                activityId: this.activityId
            };
        }
        if (this.changeSetId) {
            return {
                changeSetId: this.changeSetId
            };
        }
        if (this.changeSetAuthorityId) {
            return {
                changeSetAuthorityId: this.changeSetAuthorityId
            };
        }
        if (this.cosmicEventId) {
            return {
                cosmicEventId: this.cosmicEventId
            };
        }
        if (this.deliveryMatrixItemId) {
            return {
                deliveryMatrixItemId: this.deliveryMatrixItemId
            };
        }
        if (this.documentId) {
            return {
                documentId: this.documentId
            };
        }
        if (this.hardwareListId) {
            return {
                hardwareListId: this.hardwareListId
            };
        }
        if (this.itemInstanceId) {
            return {
                itemInstanceId: this.itemInstanceId
            };
        }
        if (this.organizationCode) {
            return {
                organizationCode: this.organizationCode
            };
        }
        if (this.physicalInventoryAuditId) {
            return {
                physicalInventoryAuditId: this.physicalInventoryAuditId
            };
        }
        if (this.physicalInventoryAuditItemId) {
            return {
                physicalInventoryAuditItemId: this.physicalInventoryAuditItemId
            };
        }
        if (this.riskId) {
            return {
                riskId: this.riskId
            };
        }
        if (this.riskMitigationId) {
            return {
                riskMitigationId: this.riskMitigationId
            };
        }
        if (this.transactionId) {
            return {
                transactionId: this.transactionId
            };
        }
        if (this.drawingNumber && !this.asBuiltNumber && !this.side) {
            return {
                drawingNumber: this.drawingNumber
            };
        }
        if (this.asBuiltNumber && this.drawingNumber && this.side) {
            return {
                asBuiltNumber: this.asBuiltNumber,
                drawingNumber: this.drawingNumber,
                side: this.side
            };
        }
        return null;
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "activityId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "asBuiltNumber", void 0);
__decorate([
    Prop({
        type: Number
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "changeSetId", void 0);
__decorate([
    Prop({
        type: Number
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "changeSetAuthorityId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "cosmicEventId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "deliveryMatrixItemId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "documentId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "drawingNumber", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "hardwareListId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "itemInstanceId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "organizationCode", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "physicalInventoryAuditId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "physicalInventoryAuditItemId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "riskId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "riskMitigationId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "side", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "transactionId", void 0);
__decorate([
    Prop({
        type: Number
    }),
    __metadata("design:type", Object)
], WagonWheelPropsMixin.prototype, "workOrderStepId", void 0);
WagonWheelPropsMixin = __decorate([
    Component
], WagonWheelPropsMixin);
export default WagonWheelPropsMixin;
