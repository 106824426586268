var _a, _b, _c, _d;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { CommentType, Maybe } from '~/db_types';
import { EntityType as DbEntityType } from '~/db_types/swagger_types';
import { BASE_ICON_COL, COMMENT_COL, SUB_TYPE_COL, UPDATED_BY_COL, UPDATED_DATE_TIME_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import WagonWheelPropsMixin from '~/nasa_ui/mixins/WagonWheelPropsMixin';
import { AlertType, EntityType, UserPermission } from '~/nasa_ui/types';
import { CommentTypeDisplay, EditableCommentTypes } from '~/nasa_ui/types/enums/comment';
import { sortObjectBy } from '~/nasa_ui/utils';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { CommentTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformComments } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let AppComments = class AppComments extends Mixins(CurrentUserMixin, ReadOnlyMixin, WagonWheelPropsMixin) {
    commentsActivity = [];
    commentsDeliveryMatrixItem = [];
    commentsDocument = [];
    commentsEvent = [];
    commentsHardwareList = [];
    commentsItemDrawing = [];
    commentsItemInstance = [];
    commentsItemMaster = [];
    commentsOrganization = [];
    commentsPia = [];
    commentsRisk = [];
    commentsRiskMitigation = [];
    CommentTableHeaders = CommentTableHeaders;
    CommentType = CommentType;
    CommentTypeDisplay = CommentTypeDisplay;
    commentTypesToDisplay = [];
    documentCommentsToDisplay = [];
    isFetching = false;
    isRemoving = false;
    selectedComment = null;
    showDeleteConfirmation = false;
    showEditModal = false;
    createCommentTypes;
    editCommentTypes;
    ignoreCommentTypes;
    tableHeaderOverrides;
    get allComments() {
        return [
            ...this.commentsActivity,
            ...this.commentsDeliveryMatrixItem,
            ...this.commentsDocument,
            ...this.commentsEvent,
            ...this.commentsHardwareList,
            ...this.commentsItemDrawing,
            ...this.commentsItemInstance,
            ...this.commentsItemMaster,
            ...this.commentsOrganization,
            ...this.commentsPia,
            ...this.commentsRisk
        ].sort(sortObjectBy('updatedDateTime', true));
    }
    get commentTypeEnum() {
        return Object.values(CommentType).filter((commentType) => this.createCommentTypes?.includes(commentType));
    }
    get computedComments() {
        if (!this.allComments) {
            return [];
        }
        const filteredComments = this.allComments.filter((comment) => {
            return this.commentTypesToDisplay?.includes(comment.subType);
        });
        return transformComments(filteredComments).sort(sortObjectBy('_updatedDateTicks', true));
    }
    get computedTableHeaders() {
        return (this.tableHeaderOverrides || [
            composeTableHeader(UPDATED_BY_COL, { width: '5%' }),
            COMMENT_COL,
            composeTableHeader(SUB_TYPE_COL, {
                text: 'Comment<br/>type',
                value: '_subType'
            }),
            composeTableHeader(BASE_ICON_COL, {
                noEscape: true,
                text: 'System<br/>type',
                value: '_entityType',
                width: '15%'
            }),
            composeTableHeader(UPDATED_DATE_TIME_COL, {
                width: '10%'
            })
        ]);
    }
    get entityTypesAssociatedWithComment() {
        if (!this.selectedComment) {
            return [];
        }
        const entityTypes = [];
        // not trying to be fancy here
        if (this.selectedComment.activityId) {
            entityTypes.push(EntityType.ACTIVITY);
        }
        if (this.selectedComment.changeSetAuthorityId || this.selectedComment.changeSetId) {
            entityTypes.push(EntityType.CHANGE_SET);
        }
        if (this.selectedComment.deliveryMatrixItemId) {
            entityTypes.push(EntityType.DELIVERY_MATRIX_ITEM);
        }
        if (this.selectedComment.documentId) {
            entityTypes.push(EntityType.DOCUMENT);
        }
        if (this.selectedComment.eventId) {
            entityTypes.push(EntityType.EVENT);
        }
        if (this.selectedComment.hardwareListId) {
            entityTypes.push(EntityType.HARDWARE_LIST);
        }
        if (this.selectedComment.itemInstanceId) {
            entityTypes.push(EntityType.ITEM_INSTANCE);
        }
        if (this.selectedComment.drawingNumber) {
            entityTypes.push(EntityType.ITEM_DRAWING);
        }
        if (this.selectedComment.asBuiltNumber && this.selectedComment.drawingNumber) {
            entityTypes.push(EntityType.ITEM_MASTER);
        }
        if (this.selectedComment.organizationCode) {
            entityTypes.push(EntityType.ORGANIZATION);
        }
        if (this.selectedComment.physicalInventoryAuditId || this.selectedComment.physicalInventoryAuditItemId) {
            entityTypes.push(EntityType.PHYSICAL_INVENTORY_AUDIT);
        }
        if (this.selectedComment.transactionId) {
            entityTypes.push(EntityType.TRANSACTION);
        }
        if (this.selectedComment.riskId) {
            entityTypes.push(EntityType.RISK);
        }
        if (this.selectedComment.riskMitigationId) {
            entityTypes.push(EntityType.RISK);
        }
        return entityTypes;
    }
    get existingCommentTypes() {
        return this.allComments?.reduce((output, comment) => {
            if (!(output.indexOf(comment?.subType) > -1)) {
                output.push(comment?.subType);
            }
            return output;
        }, []);
    }
    get existingValuesToBeRemoved() {
        return Object.values(CommentType).filter((commentType) => {
            return !this.existingCommentTypes.includes(commentType);
        });
    }
    get getPermissionForHardware() {
        return [...this.entityTypesAssociatedWithComment]
            .map((entityType) => {
            const perm = `${entityType}_ADMIN`;
            if (Object.hasOwn(UserPermission, perm)) {
                return perm;
            }
        })
            .filter((perm) => perm);
    }
    get rowsPerPageItems() {
        return [
            100,
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    get selectedCommentId() {
        return this.selectedComment?.id;
    }
    get isUserOwnerOfComment() {
        if (this.selectedComment && this.currentUser) {
            return this.selectedComment.createdByUserId === this.currentUser.id;
        }
    }
    get userCanRemoveComment() {
        if (!this.selectedComment?.comment) {
            return true; // any user can remove empty comments
        }
        if (!this.getPermissionForHardware) {
            return false;
        }
        const hasPermission = (userPermission) => this.currentUserHasPermission(userPermission);
        return this.getPermissionForHardware.some(hasPermission) || this.isUserOwnerOfComment || false;
    }
    created() {
        this.fetchComments();
    }
    onClickOfDelete(row) {
        if (this.readOnly) {
            return;
        }
        if (row.subType === CommentType.SYSTEM) {
            this.$notification.add({
                text: 'Cannot delete SYSTEM comments.',
                type: AlertType.WARNING
            });
            return;
        }
        this.selectedComment = row;
        this.showDeleteConfirmation = true;
    }
    onClickOfEdit(row) {
        if (this.readOnly) {
            return;
        }
        if (row.subType === CommentType.SYSTEM) {
            this.$notification.add({
                text: 'Cannot edit SYSTEM comments.',
                type: AlertType.WARNING
            });
            return;
        }
        this.selectedComment = row;
        this.showEditModal = true;
    }
    onDeleteCancelled() {
        this.selectedComment = null;
        this.showDeleteConfirmation = false;
    }
    // Delete the selected comment
    async onDeleteConfirmed() {
        if (!this.selectedComment) {
            return;
        }
        try {
            this.isRemoving = true;
            const resp = await this.$http.delete(`/comments/${this.selectedComment.id}`);
            this.$notification.add({
                text: 'Deleted.',
                type: AlertType.SUCCESS
            });
            this.isRemoving = false;
            this.reloadComments();
        }
        catch (err) {
            this.isRemoving = false;
            this.reloadComments();
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchCommentsForItemDrawing(drawingNumber) {
        if (!drawingNumber) {
            return;
        }
        try {
            const params = {
                drawingNumber: drawingNumber,
                entityType: [DbEntityType.ITEM_DRAWING]
            };
            const resp = await this.$http.get(`/comments`, { params });
            if (!resp.data) {
                new Error('Failed to fetch comments for item drawing.');
                return;
            }
            this.commentsItemDrawing = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchCommentsForItemMaster(drawingNumber, asBuiltNumber, side) {
        if (!drawingNumber || !asBuiltNumber || !side) {
            return;
        }
        try {
            this.fetchCommentsForItemDrawing(drawingNumber);
            const paramsIM = {
                asBuiltNumber: asBuiltNumber,
                drawingNumber: drawingNumber,
                side: side,
                entityType: [DbEntityType.ITEM_MASTER]
            };
            const respIM = await this.$http.get(`/comments`, { params: paramsIM });
            if (!respIM.data) {
                new Error('Failed to fetch comments for item master.');
                return;
            }
            this.commentsItemMaster = respIM.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchCommentsForItemInstance() {
        if (!this.itemInstanceId) {
            return;
        }
        try {
            // first get the item instance so we can get the primary keys
            const respII = await this.$http.get(`/hardware/item-instances/${this.itemInstanceId}`);
            if (!respII.data) {
                new Error('Failed to fetch item instance.');
                return;
            }
            const drawingNumber = respII.data.drawingNumber;
            const asBuiltNumber = respII.data.asBuiltNumber;
            const side = respII.data.side;
            const paramsIIComments = {
                itemInstanceId: this.itemInstanceId,
                entityType: [DbEntityType.ITEM_INSTANCE],
                take: -1
            };
            const respIIComments = await this.$http.get('/comments', {
                params: paramsIIComments
            });
            if (!respIIComments.data) {
                new Error('Failed to fetch comments for item instance.');
                return;
            }
            this.commentsItemInstance = respIIComments.data.results ?? [];
            this.fetchCommentsForItemMaster(drawingNumber, asBuiltNumber, side);
            this.fetchCommentsForItemDrawing(drawingNumber);
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchCommentsForDocument() {
        if (!this.documentId) {
            return;
        }
        try {
            const params = {
                documentId: this.documentId,
                take: -1
            };
            const resp = await this.$http.get('/comments', { params });
            if (!resp.data) {
                this.$errorUtility({ err: 'Failed to fetch comments for document.' });
                return;
            }
            this.commentsDocument = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    // Uses API2
    async fetchCommentsForHardwareList() {
        if (!this.hardwareListId) {
            return;
        }
        try {
            const resp = await this.$http.get(`/comments`, {
                params: {
                    hardwareListId: this.hardwareListId,
                    take: -1
                }
            });
            if (!resp.data) {
                new Error('Failed to fetch comments for hardware list.');
                return;
            }
            this.commentsHardwareList = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    // Uses API2
    async fetchCommentsForEvent() {
        if (!this.cosmicEventId) {
            return;
        }
        try {
            const resp = await this.$http.get(`/comments`, {
                params: {
                    cosmicEventId: this.cosmicEventId,
                    take: -1
                }
            });
            if (!resp.data) {
                new Error('Failed to fetch comments for event.');
                return;
            }
            this.commentsEvent = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchCommentsForActivity() {
        if (!this.activityId) {
            return;
        }
        try {
            const params = {
                activityId: this.activityId,
                take: -1
            };
            const resp = await this.$http.get('/comments', { params });
            if (!resp.data) {
                new Error('Failed to fetch comments for activity.');
                return;
            }
            this.commentsActivity = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    // Uses API2
    async fetchCommentsForOrganization() {
        if (!this.organizationCode) {
            return;
        }
        try {
            const resp = await this.$http.get(`/comments`, {
                params: {
                    organizationCode: this.organizationCode,
                    take: -1
                }
            });
            if (!resp.data) {
                new Error('Failed to fetch comments for organization.');
                return;
            }
            this.commentsOrganization = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchCommentsForPia() {
        if (!this.physicalInventoryAuditId) {
            return;
        }
        try {
            const params = {
                physicalInventoryAuditId: this.physicalInventoryAuditId,
                take: -1
            };
            const resp = await this.$http.get('/comments', { params });
            if (!resp.data) {
                new Error('Failed to fetch comments for PIA.');
                return;
            }
            this.commentsPia = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    // Uses API2
    async fetchCommentsForDeliveryMatrixItems() {
        if (!this.deliveryMatrixItemId) {
            return;
        }
        try {
            const resp = await this.$http.get(`/comments`, {
                params: {
                    deliveryMatrixItemId: this.deliveryMatrixItemId,
                    take: -1
                }
            });
            if (!resp.data) {
                new Error('Failed to fetch comments for delivery matrix item.');
                return;
            }
            this.commentsDeliveryMatrixItem = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    // Uses API2
    async fetchCommentsForRisk() {
        if (!this.riskId) {
            return;
        }
        try {
            const resp = await this.$http.get(`/comments`, {
                params: {
                    riskId: this.riskId,
                    take: -1
                }
            });
            if (!resp.data) {
                new Error('Failed to fetch comments for risk.');
                return;
            }
            this.commentsRisk = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    // Uses API2
    async fetchCommentsForRiskMitigationStep() {
        if (!this.riskMitigationId) {
            return;
        }
        try {
            const resp = await this.$http.get(`/comments`, {
                params: {
                    riskMitigationId: this.riskMitigationId,
                    take: -1
                }
            });
            if (!resp.data) {
                new Error('Failed to fetch comments for risk mitigation.');
                return;
            }
            this.commentsRiskMitigation = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchComments() {
        try {
            this.isFetching = true;
            // Maybe not the best but the assumption has always been you have a "pov"
            // so youll only fetch one of these at a time
            if (this.activityId) {
                await this.fetchCommentsForActivity();
            }
            if (this.deliveryMatrixItemId) {
                await this.fetchCommentsForDeliveryMatrixItems();
            }
            if (this.documentId) {
                await this.fetchCommentsForDocument();
            }
            if (this.cosmicEventId) {
                await this.fetchCommentsForEvent();
            }
            if (this.hardwareListId) {
                await this.fetchCommentsForHardwareList();
            }
            // if we have only a drawing number, look for item drawing comments
            if (this.drawingNumber && !this.asBuiltNumber && !this.side && !this.itemInstanceId) {
                await this.fetchCommentsForItemDrawing(this.drawingNumber);
            }
            // if we have a drawing number, as built number, and side, look for item master comments.
            if (this.asBuiltNumber && this.drawingNumber && this.side && !this.itemInstanceId) {
                await this.fetchCommentsForItemMaster(this.drawingNumber, this.asBuiltNumber, this.side);
            }
            // this function will also fetch comments for item master and item drawing
            if (this.itemInstanceId) {
                await this.fetchCommentsForItemInstance();
            }
            if (this.organizationCode) {
                await this.fetchCommentsForOrganization();
            }
            if (this.physicalInventoryAuditId) {
                await this.fetchCommentsForPia();
            }
            if (this.riskId) {
                await this.fetchCommentsForRisk();
            }
            if (this.riskMitigationId) {
                await this.fetchCommentsForRiskMitigationStep();
            }
            this.isFetching = false;
            this.commentTypesToDisplay =
                this.existingCommentTypes.filter((comment) => {
                    return !this.ignoreCommentTypes?.includes(comment);
                }) || [];
        }
        catch (err) {
            this.$errorUtility({
                err
            });
        }
    }
    onModalCommentEditClose() {
        this.selectedComment = null;
        this.showEditModal = false;
    }
    emitComments() { }
    commentTotalByType(commentType) {
        return this.allComments.filter((comment) => comment.subType === commentType).length;
    }
    reloadComments() {
        this.selectedComment = null;
        this.showDeleteConfirmation = false;
        this.showEditModal = false;
        this.fetchComments();
    }
    setCommentType(type) {
        this.commentTypesToDisplay = [type];
    }
    viewAllComments() {
        this.commentTypesToDisplay = this.existingCommentTypes;
    }
};
__decorate([
    Prop({
        default() {
            return Object.values(EditableCommentTypes);
        },
        type: Array
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppComments.prototype, "createCommentTypes", void 0);
__decorate([
    Prop({
        default() {
            return Object.values(EditableCommentTypes);
        },
        type: Array
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], AppComments.prototype, "editCommentTypes", void 0);
__decorate([
    Prop({
        default() {
            return [];
        },
        type: Array
    }),
    __metadata("design:type", typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object)
], AppComments.prototype, "ignoreCommentTypes", void 0);
__decorate([
    Prop({
        type: Array,
        required: false
    }),
    __metadata("design:type", typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object)
], AppComments.prototype, "tableHeaderOverrides", void 0);
__decorate([
    Emit('comments'),
    Watch('computedComments', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppComments.prototype, "emitComments", null);
AppComments = __decorate([
    Component
], AppComments);
export default AppComments;
