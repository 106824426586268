var _a, _b;
import { __decorate, __metadata } from "tslib";
import { add } from 'date-fns';
import { DebounceAll } from 'lodash-decorators';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import NewBaseHardwareDisplay from '~/nasa_ui/base/NewBaseHardwareDisplay';
import CosmicLimitedLife from '~/nasa_ui/modules/CosmicLimitedLife';
import { EntityType, LimitedLifeTypeShortDisplay } from '~/nasa_ui/types';
import { limitInterval } from '~/nasa_ui/utils/dates';
import { transformInventoryResponses, transformItemInstanceResponseWithRefs, transformItemMasterResponseWithRefs, transformLocationResponses, transformOrganizationResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let AppItemInstanceDisplay = class AppItemInstanceDisplay extends Mixins(NewBaseHardwareDisplay) {
    LimitedLifeTypeShortDisplay = LimitedLifeTypeShortDisplay;
    limitInterval = limitInterval;
    myLimitedLife = null;
    selectedEntity = null;
    entity;
    inventoryId;
    id;
    get changelogHref() {
        const from = this.$dateDisplay(add(new Date(), { years: -1 }));
        const to = this.$dateDisplay(new Date());
        if (!this.computedId) {
            return null;
        }
        return `/reporting/changelog/results?from=${from}&to=${to}&entityType=${EntityType.ITEM_INSTANCE}&id=${this.computedId}&ahd=true`;
    }
    get computedId() {
        return this.id || this.selectedEntity?.id;
    }
    get hasAnyDateField() {
        return (this.transformedSelectedEntity &&
            (Boolean(this.transformedSelectedEntity.acquisitionDate) ||
                Boolean(this.transformedSelectedEntity.birthDate) ||
                Boolean(this.transformedSelectedEntity.closeDate) ||
                Boolean(this.transformedSelectedEntity.cureDate) ||
                Boolean(this.transformedSelectedEntity.openDate) ||
                Boolean(this.transformedSelectedEntity.operationalStartDate) ||
                Boolean(this.transformedSelectedEntity.pdaDate) ||
                Boolean(this.transformedSelectedEntity.piaDate) ||
                Boolean(this.transformedSelectedEntity.purchaseRequestDate)));
    }
    get selectedEntityHasLimitedLifeQuotaDefined() {
        return Boolean(this.selectedEntity?.limitedLife);
    }
    get selectedEntityHasLimitedLifeReport() {
        return Boolean((this.selectedEntity?.limitedLifeReport || []).length);
    }
    get inventories() {
        return this.searchHardwareResults ? this.searchHardwareResults.inventories.results || [] : [];
    }
    get transformedItemMaster() {
        return this.itemMaster ? transformItemMasterResponseWithRefs([this.itemMaster])[0] : null;
    }
    get transformedInventories() {
        return transformInventoryResponses(this.inventories).map((inv) => {
            const _inv = { ...inv };
            if (_inv.locationId) {
                _inv.location = this.searchHardwareResults?.inventories.locations?.find((_loc) => _loc.id === _inv.locationId);
                if (_inv.location) {
                    _inv._location = transformLocationResponses([_inv.location])[0];
                }
            }
            if (_inv.issueDestinationLocationId) {
                _inv.issueDestinationLocation = this.searchHardwareResults?.inventories.locations?.find((_loc) => _loc.id === _inv.issueDestinationLocationId);
                if (_inv.issueDestinationLocation) {
                    _inv._issueDestinationLocation = transformLocationResponses([_inv.issueDestinationLocation])[0];
                }
            }
            if (_inv.issueDestinationOrganizationCode) {
                _inv.issueDestinationOrganization = this.searchHardwareResults?.inventories.organizations?.find((_org) => _org.code === _inv.issueDestinationOrganizationCode);
                if (_inv.issueDestinationOrganization) {
                    _inv._issueDestinationOrganization = transformOrganizationResponses([_inv.issueDestinationOrganization])[0];
                }
            }
            return _inv;
        });
    }
    get transformedSelectedEntity() {
        // if youre already transformed... return that
        if (this.selectedEntity && this.selectedEntity.hasOwnProperty('_acquisitionDate')) {
            return this.selectedEntity;
        }
        return this.selectedEntity ? transformItemInstanceResponseWithRefs([this.selectedEntity])[0] : null;
    }
    created() {
        this.$listen(`reload_${this.id}`, this.fetchTheShitYouNeed);
    }
    fetchTheShitYouNeed() {
        if (this.computedId) {
            this.fetchEntity();
        }
        this.fetchItemDrawing();
        this.fetchItemMaster();
        this.fetchLinks();
        this.fetchHardware();
    }
    async fetchItemDrawing() {
        if (!this.selectedEntity?.drawingNumber) {
            return;
        }
        try {
            this.isFetchingItemDrawing = true;
            const respItemDrawing = await this.$http.get(`/hardware/item-drawings/${encodeURIComponent(this.selectedEntity.drawingNumber)}`);
            this.itemDrawing = respItemDrawing.data;
            this.isFetchingItemDrawing = false;
        }
        catch (err) {
            this.isFetchingItemDrawing = false;
            this.$errorUtility({
                err
            });
        }
    }
    async fetchItemMaster() {
        if (!this.selectedEntity?.asBuiltNumber || !this.selectedEntity?.drawingNumber || !this.selectedEntity?.side) {
            return;
        }
        try {
            this.isFetchingItemMaster = true;
            const resp = await this.$http.get(`/hardware/item-masters/${encodeURIComponent(this.selectedEntity.drawingNumber)}/${encodeURIComponent(this.selectedEntity.asBuiltNumber)}/${this.selectedEntity.side}`);
            this.itemMaster = resp.data;
            this.isFetchingItemMaster = false;
        }
        catch (err) {
            this.isFetchingItemMaster = false;
            this.$errorUtility({
                err
            });
        }
    }
    onSelectedEntityChanged() {
        if (this.selectedEntity?.limitedLife) {
            this.myLimitedLife = new CosmicLimitedLife(this.selectedEntity.limitedLife, this.selectedEntity.limitedLifeReport);
        }
    }
    async fetchEntity() {
        if (!this.id) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.$http.get(`/hardware/item-instances/${this.id}`);
            this.selectedEntity = resp.data;
            this.isFetching = false;
        }
        catch (err) {
            this.isFetching = false;
            this.$errorUtility({
                err
            });
        }
    }
    async fetchHardware() {
        if (!this.selectedEntity ||
            !this.selectedEntity.drawingNumber ||
            !this.selectedEntity.asBuiltNumber ||
            !this.selectedEntity.side) {
            return;
        }
        try {
            this.isFetchingHardware = true;
            const params = {
                asBuiltNumber: [this.selectedEntity.asBuiltNumber],
                drawingNumber: [this.selectedEntity.drawingNumber],
                includeDrawings: false,
                includeInstances: false,
                includeInventory: true,
                includeMasters: false,
                lotNumber: this.selectedEntity.lotNumber ? [this.selectedEntity.lotNumber] : undefined,
                serialNumber: this.selectedEntity.serialNumber ? [this.selectedEntity.serialNumber] : undefined,
                side: [this.selectedEntity.side],
                take: -1
            };
            const resp = await this.$http.get(`/hardware`, {
                params
            });
            this.searchHardwareResults = resp.data;
            this.isFetchingHardware = false;
        }
        catch (err) {
            this.isFetchingHardware = false;
            this.$errorUtility({
                err
            });
        }
    }
    async fetchLinks() {
        if (!this.computedId) {
            return;
        }
        try {
            this.isFetching = true;
            const params = {
                itemInstanceId: this.computedId,
                take: -1
            };
            const respLinks = await this.$http.get(`/links`, {
                params
            });
            this.links = respLinks.data.results || [];
            this.isFetching = false;
        }
        catch (err) {
            this.isFetching = false;
            this.$errorUtility({
                err
            });
        }
    }
    onEntityChange() {
        if (this.id && this.entity) {
            console.log('Shouldnt set both id AND entity');
            return;
        }
        this.selectedEntity = { ...this.entity };
    }
};
__decorate([
    Prop({
        required: false,
        type: Object
    }),
    __metadata("design:type", Object)
], AppItemInstanceDisplay.prototype, "entity", void 0);
__decorate([
    Prop({
        type: String,
        required: false
    }),
    __metadata("design:type", String)
], AppItemInstanceDisplay.prototype, "inventoryId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppItemInstanceDisplay.prototype, "id", void 0);
__decorate([
    DebounceAll(20),
    Watch('selectedEntity.drawingNumber'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppItemInstanceDisplay.prototype, "fetchItemDrawing", null);
__decorate([
    DebounceAll(20),
    Watch('selectedEntity.asBuiltNumber'),
    Watch('selectedEntity.drawingNumber'),
    Watch('selectedEntity.side'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppItemInstanceDisplay.prototype, "fetchItemMaster", null);
__decorate([
    Watch('selectedEntity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppItemInstanceDisplay.prototype, "onSelectedEntityChanged", null);
__decorate([
    DebounceAll(20),
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppItemInstanceDisplay.prototype, "fetchEntity", null);
__decorate([
    DebounceAll(20),
    Watch('selectedEntity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppItemInstanceDisplay.prototype, "fetchHardware", null);
__decorate([
    DebounceAll(20),
    Watch('computedId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppItemInstanceDisplay.prototype, "fetchLinks", null);
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppItemInstanceDisplay.prototype, "onEntityChange", null);
AppItemInstanceDisplay = __decorate([
    Component
], AppItemInstanceDisplay);
export default AppItemInstanceDisplay;
