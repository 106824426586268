import uniqWith from 'lodash/uniqWith';
import { ChangeSetType, Side, TransactionType } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { NOT_APPLICABLE } from '~/nasa_ui/constants/display';
import { addIconsAndClasses, createTableItemFromHardwareItem, createTableItemFromTemplateItem, handleEventAssemblyItems } from '~/nasa_ui/constants/hardwareListConstants';
import { getAS2DownloadUrl } from '~/nasa_ui/DAL/attachments';
import { EntityType, EntityTypeSingularDisplay } from '~/nasa_ui/types';
import { TransactionTypeDisplay } from '~/nasa_ui/types/enums/assembly';
import { AuthorityCodeDisplay } from '~/nasa_ui/types/enums/authority-codes';
import { DeliveryMatrixItemStatusDisplay, DeliveryMatrixItemTypeDisplay, DeliveryMatrixNeedTypeDisplay } from '~/nasa_ui/types/enums/delivery-matrix-item';
import { DocumentTypeDisplay, EztDocumentTypeDisplay } from '~/nasa_ui/types/enums/document';
import { FederalSupplyClassDisplay } from '~/nasa_ui/types/enums/federal-supply-class';
import { HardwareListTypeDisplay, InventoryMethodDisplay, ItemInstanceInventoryTypeDisplay, ItemMasterPropertyClassDisplay } from '~/nasa_ui/types/enums/hardware';
import { currencyDisplay, isNullOrUndefined, sortObjectBy } from '~/nasa_ui/utils';
import { dateDisplay } from '../dates/dateDisplay';
import { dateTimeDisplay } from '../dates/dateTimeDisplay';
import { buildIconAsString } from '../ui/buildIconAsString';
import { CosmicIcons, getIconByContentType } from '../ui/CosmicIcons';
import * as filters from './displayMappers';
import { booleanIconDisplay, contactDisplay, itemNumberDisplay, locationDisplay, numberDisplay, quantityDisplay } from './displays';
import { markdownToHtml } from './markdownToHtml';
import { sortObjectNumericallyBy } from './sortObjectNumericallyBy';
import { transformActivities } from './transforms/activities';
import { transformAssemblyTemplates } from './transforms/assemblyTemplates';
import { transformChangeSets } from './transforms/changeSets';
import { transformComments } from './transforms/comments';
import { transformContracts } from './transforms/contracts';
import { transformEvents } from './transforms/events';
import { transformInventories, transformInventoryResponses, transformItemDrawingResponses, transformItemDrawings, transformItemInstanceResponses, transformItemInstances, transformItemMasterResponses, transformItemMasters } from './transforms/hardware';
import { transformLocations } from './transforms/locations';
import { transformOrganizations } from './transforms/organizations';
import { transformPhysicalInventoryAudit } from './transforms/physicalInventoryAudits';
import { transformUserGroups, transformUserResponses } from './transforms/users';
export * from './transforms/activities';
export * from './transforms/approvalRoutingTemplates';
export * from './transforms/assemblies';
export * from './transforms/assemblyTemplates';
export * from './transforms/attachments';
export * from './transforms/changeSets';
export * from './transforms/comments';
export * from './transforms/emailNotifications';
export * from './transforms/events';
export * from './transforms/hardware';
export * from './transforms/links';
export * from './transforms/locations';
export * from './transforms/organizations';
export * from './transforms/physicalInventoryAudits';
export * from './transforms/risks';
export * from './transforms/users';
export * from './transforms/vehicles';
// todo: transform connections, including comments and hardware
export const transformDeliveryMatrixItems = (items) => {
    return items
        .map(filters.addDateDisplays)
        .map(filters.addDescriptionDisplay)
        .map(filters.addContactDisplays)
        .map((item) => {
        item._status = DeliveryMatrixItemStatusDisplay.get(item.status) || DEFAULT_DASH;
        item._subType = DeliveryMatrixItemTypeDisplay.get(item.subType) || DEFAULT_DASH;
        item._deliverableItemsListNumber = item.deliverableItemsListNumber || DEFAULT_DASH;
        // need type is an array of strings
        item._needType = item.needType ? DeliveryMatrixNeedTypeDisplay.get(item.needType) : '';
        // contract ending item number. we're displaying this by slot but this
        // makes it searchable on the model by the the v-data-table.
        item._contractEndingItemNumber = item.hardwareListContractEndingItemNumbers?.join(', ') || DEFAULT_DASH;
        // todo
        item._latestComment = DEFAULT_DASH;
        item._commentDate = DEFAULT_DASH;
        return item;
    });
};
export const transformDocuments = (items) => {
    return items
        .map(filters.addChangeSetStatusCountDisplay)
        .map(filters.addComputedRevisionNumber)
        .map(filters.addComputedStatusDisplay)
        .map(filters.addContactDisplays)
        .map(filters.addDateDisplays)
        .map(filters.addDescriptionDisplay)
        .map(filters.addInitializationStatusDisplay)
        .map(filters.addSubTypeDisplay(DocumentTypeDisplay))
        .map(filters.addTitleDisplay)
        .map(filters.addUserDisplays)
        .map((item) => {
        const _item = { ...item };
        _item._document = _item.document ? transformDocuments([_item.document])[0] : null;
        // EZT only
        if (_item.eztType) {
            _item._eztType = EztDocumentTypeDisplay.get(_item.eztType) || DEFAULT_DASH;
        }
        return _item;
    });
};
export const transformDocumentWorkflows = (items) => {
    return items
        .map(filters.addComputedStatusDisplay)
        .map(filters.addContactDisplays)
        .map(filters.addDateDisplays)
        .map(filters.addTitleDisplay)
        .map(filters.addUserDisplays)
        .map(filters.addInitializationStatusDisplay)
        .map(filters.addSubTypeDisplay(DocumentTypeDisplay))
        .map((item) => {
        const _item = { ...item };
        _item._workflowDocumentNumber = _item.workflowDocumentNumber || DEFAULT_DASH;
        _item._workflowLetterNumber = _item.workflowLetterNumber || DEFAULT_DASH;
        _item._workflowNotes = _item.workflowNotes || DEFAULT_DASH;
        _item._document = _item.document ? transformDocuments([_item.document])[0] : null;
        return _item;
    });
};
export const transformWorkOrderSteps = (items) => {
    return items
        .map(filters.addDateDisplays)
        .map(filters.addComputedStatusDisplay)
        .map((item) => {
        const _item = { ...item };
        _item._changeSet = _item.changeSet ? transformChangeSets([_item.changeSet])[0] : null;
        return _item;
    });
};
export const transformDocumentRcas = (items) => {
    return items
        .map(filters.addComputedStatusDisplay)
        .map(filters.addContactDisplays)
        .map(filters.addDateDisplays)
        .map(filters.addTitleDisplay)
        .map(filters.addUserDisplays)
        .map(filters.addInitializationStatusDisplay)
        .map(filters.addSubTypeDisplay(DocumentTypeDisplay))
        .map((item) => {
        const _item = { ...item };
        _item._document = _item.document ? transformDocuments([_item.document])[0] : null;
        return _item;
    });
};
export const transformStandardColumns = (items) => {
    return items
        .map(filters.addItemNumberDisplay)
        .map(filters.addLotNumberDisplay)
        .map(filters.addSerialNumberDisplay)
        .map(filters.addAsBuiltNumberDisplay)
        .map(filters.addDescriptionDisplay)
        .map(filters.addSubTypeDisplay(ItemInstanceInventoryTypeDisplay));
};
export const transformHardwareLists = (items) => {
    return items
        .map(filters.addUserDisplays)
        .map(filters.addDateDisplays)
        .map(filters.addSubTypeDisplay(HardwareListTypeDisplay))
        .map((list) => {
        if (!list) {
            return;
        }
        const _document = list.document ? transformDocuments([list.document])[0] : null;
        const _event = list.event ? transformEvents([list.event])[0] : null;
        // This is bc we are sometimes using gql and sometimes using api2
        const localComments = (list.comments ? list.comments.nodes : list.comments) || [];
        const _comments = localComments.length > 0
            ? `<ul>${list.comments.nodes
                .map((comment) => `<li>${markdownToHtml(comment?.comment)}</li>`)
                .join('')}</ul>`
            : DEFAULT_DASH;
        return {
            ...list,
            _builtUsingTemplate: booleanIconDisplay(!isNullOrUndefined(list.hardwareListTemplateId)),
            _comments,
            _document,
            _event,
            _itemCount: numberDisplay(list.assemblies?.length),
            _number: list.number || DEFAULT_DASH,
            _name: list.name || DEFAULT_DASH,
            _revision: list.revision || DEFAULT_DASH
        };
    });
};
export const transformHardwareListResponses = (items) => {
    return transformHardwareLists(items);
};
// TODO: break into sr map functions
export const transformHardwareListTemplates = (items) => {
    return items.map((item) => {
        const _name = item?.name || DEFAULT_DASH;
        const hardwareListTemplateSlotsCount = quantityDisplay(item?.hardwareListTemplateSlots?.totalCount || 0);
        const hardwareListCount = quantityDisplay(item?.hardwareLists?.totalCount || 0);
        const hardwareListTemplate = item;
        const nodeId = item?.nodeId || DEFAULT_DASH;
        return {
            _name,
            id: item?.id,
            hardwareListTemplateSlotsCount,
            hardwareListCount,
            hardwareListTemplate,
            nodeId
        };
    });
};
export const transformInventoryTransactions = (items) => {
    return items
        .map(filters.addUserDisplays)
        .map(filters.addDateDisplays)
        .map((item) => {
        const authorityCode = item.authorityCode
            ? `<strong>${AuthorityCodeDisplay.get(item.authorityCode)}</strong>: `
            : '';
        const _authority = authorityCode || item.authorityNumber ? `${authorityCode}${item.authorityNumber || ''}` : DEFAULT_DASH;
        const _subType = TransactionTypeDisplay.get(item.subType) || DEFAULT_DASH;
        const _comment = item.computedCommentText ? markdownToHtml(item.computedCommentText) : DEFAULT_DASH;
        const _fromLocation = locationDisplay(item.fromLocation, null, null, false);
        const _toLocation = locationDisplay(item.toLocation, null, null, false);
        const _unitCost = currencyDisplay(item.unitCost);
        const _quantity = item.subType === TransactionType.INVENTORY_CHECK ? DEFAULT_DASH : item.quantity.toString();
        const _item = Object.assign({}, { ...item }, {
            _authority,
            _comment,
            _quantity,
            _subType,
            _unitCost,
            _fromLocation,
            _toLocation
        });
        return _item;
    })
        .sort(sortObjectBy('_transactionDate', true));
};
export const buildAttachmentDownloadLink = (attachment) => {
    const contentType = attachment.contentType;
    const url = getAS2DownloadUrl(attachment.id, attachment.fileName || '');
    return `<a href="${url}" target="_blank" download title="Download">
    <i class="delinkify ${contentType ? getIconByContentType(contentType) : ''}"></i>
  </a>`;
};
export const buildAttachmentDownloadLinkWithFileName = (attachment) => {
    return getAS2DownloadUrl(attachment.id, attachment.fileName || '');
};
export const buildIcon = (iconType, title) => {
    if (iconType === 'action') {
        return buildIconAsString({
            iconClass: `${CosmicIcons.actions} mr-2`,
            title: 'Relates to RID Action',
            color: EntityType.CHANGE_SET
        });
    }
    if (iconType === 'milestone') {
        return buildIconAsString({
            iconClass: `${CosmicIcons.milestones} mr-2`,
            title: 'Relates to an Event Milestone',
            color: EntityType.EVENT
        });
    }
    if (iconType === EntityType.RISK) {
        return buildIconAsString({
            iconClass: `${CosmicIcons[EntityType.RISK]} mr-2`,
            title: 'Relates to a Risk',
            color: 'black'
        });
    }
    if (!title) {
        title = EntityTypeSingularDisplay.get(iconType) ? `Relates to ${EntityTypeSingularDisplay.get(iconType)}` : '';
    }
    return buildIconAsString({
        iconClass: `${CosmicIcons[iconType]} mr-2`,
        title,
        color: iconType
    });
};
export const buildEntityTypeAsString = (entity) => {
    // COSMIC TYPE
    const hasActivity = entity.activityId;
    const hasChangeSet = entity.changeSetId;
    const hasChangeSetAuthority = entity.changeSetAuthorityId;
    const hasDeliveryMatrixItem = entity.deliveryMatrixItemId;
    const hasDocument = entity.documentId;
    const hasEvent = entity.eventId;
    const hasHardwareList = entity.hardwareListId;
    const hasItemDrawing = Boolean(entity.drawingNumber);
    const hasItemInstance = entity.itemInstanceId;
    const hasItemMaster = entity.drawingNumber && entity.asBuiltNumber;
    const hasOrganization = entity.organizationCode;
    const hasPhysicalInventoryAudit = entity.physicalInventoryAuditId;
    const hasRisk = entity.riskId || entity.riskMitigationId;
    const hasTransaction = entity.transactionId;
    const entityTypesAsString = [];
    if (hasActivity) {
        entityTypesAsString.push(EntityTypeSingularDisplay.get(EntityType.ACTIVITY) || '');
    }
    if (hasChangeSet) {
        entityTypesAsString.push(EntityTypeSingularDisplay.get(EntityType.CHANGE_SET) || '');
    }
    else if (hasChangeSetAuthority) {
        entityTypesAsString.push(EntityTypeSingularDisplay.get(EntityType.CHANGE_SET) || '');
    }
    if (hasDeliveryMatrixItem) {
        entityTypesAsString.push(EntityTypeSingularDisplay.get(EntityType.DELIVERY_MATRIX_ITEM) || '');
    }
    if (hasDocument) {
        entityTypesAsString.push(EntityTypeSingularDisplay.get(EntityType.DOCUMENT) || '');
    }
    if (hasEvent) {
        entityTypesAsString.push(EntityTypeSingularDisplay.get(EntityType.EVENT) || '');
    }
    if (hasHardwareList) {
        entityTypesAsString.push(EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST) || '');
    }
    if (hasItemInstance) {
        entityTypesAsString.push(EntityTypeSingularDisplay.get(EntityType.ITEM_INSTANCE) || '');
    }
    if (hasItemMaster) {
        entityTypesAsString.push(EntityTypeSingularDisplay.get(EntityType.ITEM_MASTER) || '');
    }
    if (hasItemDrawing) {
        entityTypesAsString.push(EntityTypeSingularDisplay.get(EntityType.ITEM_DRAWING) || '');
    }
    if (hasOrganization) {
        entityTypesAsString.push(EntityTypeSingularDisplay.get(EntityType.ORGANIZATION) || '');
    }
    if (hasPhysicalInventoryAudit) {
        entityTypesAsString.push(EntityTypeSingularDisplay.get(EntityType.PHYSICAL_INVENTORY_AUDIT) || '');
    }
    if (hasTransaction) {
        entityTypesAsString.push(EntityTypeSingularDisplay.get(EntityType.TRANSACTION) || '');
    }
    if (hasRisk) {
        entityTypesAsString.push(EntityTypeSingularDisplay.get(EntityType.RISK) || '');
    }
    return entityTypesAsString.join(', ');
};
export const buildEntityTypeIcons = (entity) => {
    // COSMIC TYPE
    const hasActivity = entity.activityId;
    const hasChangeSet = entity.changeSetId;
    const hasChangeSetAuthority = entity.changeSetAuthorityId;
    const hasDeliveryMatrixItem = entity.deliveryMatrixItemId;
    const hasDocument = entity.documentId;
    const hasEvent = entity.eventId;
    const hasHardwareList = entity.hardwareListId;
    const hasItemDrawing = Boolean(entity.drawingNumber);
    const hasItemInstance = entity.itemInstanceId;
    const hasItemMaster = entity.drawingNumber && entity.asBuiltNumber;
    const hasOrganization = entity.organizationCode;
    const hasPhysicalInventoryAudit = entity.physicalInventoryAuditId;
    const hasTransaction = entity.transactionId;
    const hasRisk = entity.riskId || entity.riskMitigationId;
    const icons = [];
    if (hasActivity) {
        icons.push(buildIcon(EntityType.ACTIVITY));
    }
    if (hasChangeSet && entity.changeSet?.subType === ChangeSetType.COSMIC_ACTION) {
        icons.push(buildIcon('action'));
    }
    else if (hasChangeSet) {
        icons.push(buildIcon(EntityType.CHANGE_SET));
    }
    else if (hasChangeSetAuthority) {
        icons.push(buildIcon(EntityType.CHANGE_SET));
    }
    if (hasDocument) {
        icons.push(buildIcon(EntityType.DOCUMENT));
    }
    if (hasDeliveryMatrixItem) {
        icons.push(buildIcon(EntityType.DELIVERY_MATRIX_ITEM));
    }
    if (hasEvent) {
        icons.push(buildIcon(EntityType.EVENT));
    }
    if (hasHardwareList) {
        icons.push(buildIcon(EntityType.HARDWARE_LIST));
    }
    if (hasItemInstance) {
        icons.push(buildIcon(EntityType.ITEM_INSTANCE));
    }
    if (hasItemMaster) {
        icons.push(buildIcon(EntityType.ITEM_MASTER));
    }
    if (hasItemDrawing) {
        icons.push(buildIcon(EntityType.ITEM_DRAWING));
    }
    if (hasOrganization) {
        icons.push(buildIcon(EntityType.ORGANIZATION));
    }
    if (hasPhysicalInventoryAudit) {
        icons.push(buildIcon(EntityType.PHYSICAL_INVENTORY_AUDIT));
    }
    if (hasRisk) {
        icons.push(buildIcon(EntityType.RISK));
    }
    if (hasTransaction) {
        icons.push(buildIcon(EntityType.TRANSACTION));
    }
    return icons.join('');
};
// Still need this for BaseSearch.ts in cosmic_web
export const transformEntitiesToTableFormat = (entityType, items) => {
    switch (entityType) {
        case EntityType.ACTIVITY:
            return transformActivities(items);
        case EntityType.ASSEMBLY_TEMPLATE:
            return transformAssemblyTemplates(items);
        case EntityType.COMMENT:
            return transformComments(items);
        case EntityType.CHANGE_SET:
            return transformChangeSets(items);
        case EntityType.CONTRACT:
            return transformContracts(items);
        case EntityType.DOCUMENT:
            return transformDocuments(items);
        case EntityType.EVENT:
            return transformEvents(items);
        case EntityType.HARDWARE_LIST:
            return transformHardwareLists(items);
        case EntityType.HARDWARE_LIST_TEMPLATE:
            return transformHardwareListTemplates(items);
        case EntityType.INVENTORY:
            return transformInventories(items);
        case EntityType.ITEM_DRAWING:
            return transformItemDrawings(items);
        case EntityType.ITEM_INSTANCE:
            return transformItemInstances(items);
        case EntityType.ITEM_MASTER:
            return transformItemMasters(items);
        case EntityType.LOCATION:
            return transformLocations(items);
        case EntityType.ORGANIZATION:
            return transformOrganizations(items);
        case EntityType.PHYSICAL_INVENTORY_AUDIT:
            return transformPhysicalInventoryAudit(items);
        case EntityType.USER:
            return transformUserResponses(items);
        case EntityType.USER_GROUP:
            return transformUserGroups(items);
        default:
            return items;
    }
};
export const transformHardwareListAssemblyResponses = (items) => {
    return transformHardwareListAssemblies(items);
};
/**
 *
 * @param items
 * @returns
 */
export const transformHardwareListAssemblies = (items) => {
    return items
        .map(filters.addAddendumNumberDisplay)
        .map(filters.addDateDisplays)
        .map(filters.addHardwareItemAssemblyTemplateDisplay)
        .map(filters.addHardwareListNumberDisplay)
        .map(filters.addNameDisplay)
        .map(filters.addNotesDisplay)
        .map(filters.addQuantityDisplay)
        .map(filters.addSequenceNumberDisplay)
        .map((item) => {
        const _item = { ...item };
        _item._externalAsBuiltNumber = item.externalAsBuiltNumber || DEFAULT_DASH;
        _item._externalCalibrationNumber = item.externalCalibrationNumber || DEFAULT_DASH;
        _item._externalClass = item.externalClass
            ? ItemInstanceInventoryTypeDisplay.get(item.externalClass) || DEFAULT_DASH
            : DEFAULT_DASH;
        _item._externalContractEndingItemNumber = item.externalContractEndingItemNumber || DEFAULT_DASH;
        _item._externalDescription = item.externalDescription || DEFAULT_DASH;
        _item._externalDrawingNumber = item.externalDrawingNumber || DEFAULT_DASH;
        _item._externalLocation = item.externalLocation || DEFAULT_DASH;
        _item._externalLotNumber = item.externalLotNumber || DEFAULT_DASH;
        _item._externalSerialNumber = item.externalSerialNumber || DEFAULT_DASH;
        _item._externalUsageLifeDate = item.externalUsageLifeDate || DEFAULT_DASH;
        _item._externalShelfLifeDate = item.externalShelfLifeDate || DEFAULT_DASH;
        _item._inventory = _item.inventory
            ? transformInventoryResponses([_item.inventory])[0]
            : null;
        _item._itemInstance = _item.itemInstance
            ? transformItemInstanceResponses([_item.itemInstance])[0]
            : null;
        _item._itemMaster = _item.itemMaster
            ? transformItemMasterResponses([_item.itemMaster])[0]
            : null;
        _item._itemDrawing = _item.itemDrawing
            ? transformItemDrawingResponses([_item.itemDrawing])[0]
            : null;
        _item._computedAsBuiltNumber = _item.isExternal
            ? _item._externalAsBuiltNumber
            : _item._inventory?._asBuiltNumber || DEFAULT_DASH;
        _item._computedBirthDate = _item.isExternal
            ? _item._externalBirthDate
            : _item._inventory?._birthDate || DEFAULT_DASH;
        _item._computedDescription = _item.isExternal
            ? _item._externalDescription
            : _item._inventory?._description || DEFAULT_DASH;
        _item._computedDrawingNumber = _item.isExternal
            ? _item._externalDrawingNumber
            : _item._inventory?.drawingNumber || DEFAULT_DASH;
        _item._computedLocation = _item.isExternal
            ? _item._externalLocation
            : _item._inventory?._location || DEFAULT_DASH;
        _item._computedLotNumber = _item.isExternal
            ? _item._externalLotNumber
            : _item._inventory?._lotNumber || DEFAULT_DASH;
        _item._computedSerialNumber = _item.isExternal
            ? _item._externalSerialNumber
            : _item._inventory?._serialNumber || DEFAULT_DASH;
        _item._computedSide = _item.isExternal ? DEFAULT_DASH : _item._inventory?._side || DEFAULT_DASH;
        _item._computedSize = _item.isExternal ? DEFAULT_DASH : _item._inventory?._size || DEFAULT_DASH;
        _item._computedSubType = _item.isExternal ? _item._externalClass : _item._inventory?._subType || DEFAULT_DASH;
        _item._computedContractEndingItemNumber = _item.isExternal
            ? _item._externalContractEndingItemNumber
            : _item._itemMaster?.contractEndingItemNumber || DEFAULT_DASH;
        _item._computedBirthDate = _item.isExternal
            ? _item._externalBirthDate
            : _item._itemInstance?._birthDate || DEFAULT_DASH;
        // TODO: We currently dont get back activities
        _item._computedCalibrationNumber = _item.isExternal ? _item._externalCalibrationNumber : DEFAULT_DASH;
        _item._computedCalibrationDate = _item.isExternal ? _item._externalCalibrationDate : DEFAULT_DASH;
        _item._computedUsageExpiryDate = _item.isExternal ? _item._externalUsageLifeDate : DEFAULT_DASH;
        _item._computedShelfLifeExpiryDate = _item.isExternal ? _item._externalShelfLifeDate : DEFAULT_DASH;
        return _item;
    });
};
export const transformHardwareItems = (items) => {
    return items
        .map(filters.addAddendumDateDisplay)
        .map(filters.addAddendumNumberDisplay)
        .map(filters.addAsBuiltNumberDisplay)
        .map(filters.addDescriptionDisplay)
        .map(filters.addHardwareItemAssemblyTemplateDisplay)
        .map(filters.addItemNumberDisplay)
        .map(filters.addLocationDisplay)
        .map(filters.addLotNumberDisplay)
        .map(filters.addNameDisplay)
        .map(filters.addNotesDisplay)
        .map(filters.addQuantityDisplay)
        .map(filters.addSequenceNumberDisplay)
        .map(filters.addSerialNumberDisplay)
        .map(filters.addSubTypeDisplay(ItemInstanceInventoryTypeDisplay))
        .map((item) => {
        const _item = { ...item };
        if (Object.keys(_item).filter((key) => key.startsWith('external')).length > 0 &&
            _item._itemNumber === DEFAULT_DASH) {
            // build obj to pass to itemNumberDisplay from external____ keys
            const hw = {
                asBuiltNumber: _item.externalAsBuiltNumber ?? null,
                drawingNumber: _item.externalDrawingNumber ?? null,
                lotNumber: _item.externalLotNumber ?? null,
                serialNumber: _item.externalSerialNumber ?? null,
                side: _item.externalSide ?? Side.NONE,
                size: _item.externalSize ?? null
            };
            _item._itemNumber = itemNumberDisplay(hw);
        }
        // BIRTH DATE
        if (_item.inventory?.itemInstance) {
            _item._birthDate = dateDisplay(_item.inventory?.itemInstance?.birthDate);
        }
        else if (_item.isExternal) {
            _item._birthDate = dateDisplay(_item.externalBirthDate);
        }
        // CEI
        _item._contractEndingItemNumber =
            _item.externalContractEndingItemNumber || _item.inventory?.itemMaster?.contractEndingItemNumber || DEFAULT_DASH;
        // CALIBRATION
        _item._calibrationNumber = _item.externalCalibrationNumber || DEFAULT_DASH;
        _item._calibrationDate = dateDisplay(_item.externalCalibrationDate);
        // USAGE EXPIRY
        _item._usageExpiryDate = dateDisplay(_item.externalUsageLifeDate);
        _item._inventory = _item.inventory
            ? transformInventoryResponses([_item.inventory])[0]
            : null;
        return _item;
    });
};
/**
 * @param  {HardwareListSlotFragment[]} templateItems
 * @param  {HardwareItemsDisplay[]} transformedHardwareItems
 * @returns IHardwareItem
 *
 * Maps over existing hardware list template items and tries to find a match against
 * a list of hardware items. If it finds one, it copies the hardware information to the table item,
 * changes the column icon to show that the template has been filled, removes the duplicate
 * matching item from the hardware list array, and returns said array for the table to display.
 *
 */
export const transformHardwareListTableItemsForTemplates = (templateItems, transformedHardwareItems) => {
    let hardwareItems = transformedHardwareItems;
    return templateItems
        .map(createTableItemFromTemplateItem)
        .map((item) => {
        const matchingHardware = hardwareItems.find((_) => _._sequence == item._sequence);
        if (matchingHardware) {
            item.hardware = matchingHardware;
            item.nodeId = matchingHardware.nodeId;
            item._addendumDate = matchingHardware._addendumDate;
            item._addendumNumber = matchingHardware._addendumNumber;
            item._asBuiltNumber = matchingHardware._asBuiltNumber;
            item._assemblyTemplate = matchingHardware._assemblyTemplate;
            item._assemblyTemplateId = matchingHardware._assemblyTemplateId;
            item._description = matchingHardware._description;
            item._itemNumber = matchingHardware._itemNumber;
            item._lotNumber = matchingHardware._lotNumber;
            item._name = matchingHardware?._assemblyTemplate?.name || NOT_APPLICABLE;
            item._notes = matchingHardware._notes;
            item._quantity = numberDisplay(matchingHardware?.quantity);
            item._serialNumber = matchingHardware._serialNumber;
            hardwareItems = hardwareItems.filter((_) => _._sequence !== item._sequence);
        }
        return item;
    })
        .concat(hardwareItems.map(createTableItemFromHardwareItem))
        .map(handleEventAssemblyItems)
        .map(addIconsAndClasses)
        .sort(sortObjectNumericallyBy('sequence'));
};
export const transformCombinedHardwareListAssemblies = (combinedLists) => {
    const flatList = combinedLists.flatMap((list) => list?.hardwareListAssemblies);
    const uniqList = uniqWith(flatList, (a, b) => {
        if (a?.inventory && b?.inventory) {
            return a.inventory.id == b.inventory.id;
        }
        return (a?.externalDescription === b?.externalDescription &&
            a?.externalSerialNumber === b?.externalSerialNumber &&
            a?.externalDrawingNumber === b?.externalDrawingNumber);
    });
    return transformHardwareListAssemblies(uniqList);
};
// todo: looks like we aren't using entityType so we should eventually remove it
export const transformChangelogs = (changeLogs, entityType) => {
    return changeLogs.flatMap((log, i, logs) => {
        return Object.entries(log.changeDetails.changedFrom || {})
            .filter(([key]) => !CHANGELOG_KEY_BLACKLIST.includes(key))
            .map(([key, val]) => {
            let attributeDisplay = changelogLabelDisplayMapper[key];
            if (!attributeDisplay) {
                console.warn(`Found a changelog key of ${key} but no matching display mapper was available`);
                attributeDisplay = key;
            }
            return {
                _createdDateTime: dateTimeDisplay(log.createdDateTime),
                _key: attributeDisplay,
                _previous: changelogValueDisplayMapper(key, val),
                _updated: changelogValueDisplayMapper(key, log.changeDetails.changedTo?.[key]),
                _user: contactDisplay(log.createdByUser)
            };
        });
    });
};
export const changelogDbKeyMapper = {
    [EntityType.DELIVERY_MATRIX_ITEM]: ['estimatedCompletionDate', 'needType'],
    [EntityType.ITEM_INSTANCE]: [
        'acquisitionDate',
        'serialNumber',
        'size',
        'equipmentControlNumber',
        'overrideHazardousMaterialText',
        'overrideMeanTimeToRepair',
        'overrideSoftwareVersion',
        'asDesignNumber',
        'piaDate',
        'cureDate',
        'birthDate',
        'pdaDate',
        'purchaseRequestDate',
        'manufactureDate',
        'operationalStartDate'
    ],
    [EntityType.ITEM_MASTER]: [
        'categoryCode',
        'cleanlinessTrackingText',
        'componentId',
        'contractEndingItemLevel',
        'contractEndingItemNumber',
        'criticalityCode',
        'federalSupplyClass',
        'greenProcurementCode',
        'groundSupportEquipment',
        'hasSoftwareVersion',
        'hazardousMaterialText',
        'inventoryMethod',
        'isApprovedAsDesign',
        'isApprovedDataPack',
        'isCleanlinessTracking',
        'isFractureCritical',
        'isHazardousMaterial',
        'isPhantom',
        'isSpecialHandling',
        'isSpecialPackaging',
        'isTool',
        'opsNom',
        'planning',
        'referenceDesignator',
        'sizes',
        'softwareVersion',
        'specialHandlingText',
        'specialPackagingText',
        'propertyClass',
        'isRefurbishable',
        'subType'
    ],
    [EntityType.ITEM_DRAWING]: [
        'description',
        'eeeIdentifier',
        'pointOfContact',
        'meanTimeToRepair',
        'meanTimeBetweenFailure',
        'aliases'
    ]
};
export const changelogLabelDisplayMapper = {
    // DELIVERY MATRIX ITEM
    estimatedCompletionDate: 'Estimated completion',
    needType: 'Need',
    // ITEM INSTANCE
    acquisitionDate: 'Acquisition date',
    asDesignNumber: 'AsDesign number',
    birthDate: 'Birth date',
    cureDate: 'Cure date',
    equipmentControlNumber: 'Equipment Control Number (ECN)',
    manufactureDate: 'Manufacture date',
    operationalStartDate: 'Operational Start date',
    overrideHazardousMaterialText: 'Override SDS / Hazardous Text',
    overrideMeanTimeToRepair: 'Override Mean Time to Repair (MTTR)',
    overrideSoftwareVersion: 'Override Software Version',
    pdaDate: 'PDA date',
    piaDate: 'PIA date',
    // purchaseRequestDate: 'Purchase Request date',
    serialNumber: 'Serial number',
    size: 'Size',
    // ITEM MASTER
    categoryCode: 'Category Code',
    cleanlinessTrackingText: 'Cleanliness Tracking Text',
    componentId: 'Component ID',
    contractEndingItemLevel: 'CEI Level',
    contractEndingItemNumber: 'CEI number',
    criticalityCode: 'Criticality Code',
    federalSupplyClass: 'Federal Supply Class',
    greenProcurementCode: 'Green Procurement Code',
    groundSupportEquipment: 'Ground Support Equipment',
    hasSoftwareVersion: 'Has Software Version',
    hazardousMaterialText: 'Hazardous Material Text',
    inventoryMethod: 'Inventory Method',
    isApprovedAsDesign: 'Approved AsDesign',
    isApprovedDataPack: 'ADP?',
    isCleanlinessTracking: 'Cleanliness Tracking',
    isFractureCritical: 'Fracture critical',
    isHazardousMaterial: 'Hazardous material',
    isPhantom: 'Phantom',
    isRefurbishable: 'Refurbishable',
    isSpecialHandling: 'Special handling',
    isSpecialPackaging: 'Special packaging',
    isTool: 'Tool',
    opsNom: 'Operations nomenclature',
    planning: 'Planning',
    propertyClass: 'Property class',
    referenceDesignator: 'Reference designator',
    sizes: 'Sizes',
    softwareVersion: 'Software version',
    specialHandlingText: 'Special handling text',
    specialPackagingText: 'Special packaging text',
    subType: 'Sub Type',
    // ITEM DRAWING
    aliases: 'Aliases',
    description: 'Description',
    eeeIdentifier: 'EEE Identifier',
    meanTimeBetweenFailure: 'Mean Time Between Failure (MTBF)',
    meanTimeToRepair: 'Mean Time to Repair (MTTR)',
    pointOfContact: 'Point of Contact'
};
const CHANGELOG_KEY_BLACKLIST = ['querySearch']; // Don't show these in any changelog tables
const changeLogValueDisplayOverrides = {
    sizes: (val) => val.join(', '),
    planning: (val) => val.makeBuySupply,
    federalSupplyClass: (val) => FederalSupplyClassDisplay.get(val),
    propertyClass: (val) => ItemMasterPropertyClassDisplay.get(val),
    inventoryMethod: (val) => InventoryMethodDisplay.get(val),
    groundSupportEquipment: (val) => (val?.subType ? `Type: ${val.subType}, ` : '') +
        (val?.testStandName ? `Name: ${val.testStandName}, ` : '') +
        (val?.testStandNumber ? `Number: ${val.testStandNumber}, ` : '') +
        (val?.etcaNomenclature ? `Nom: ${val.etcaNomenclature}, ` : '') +
        (val?.gaugeTrackingComments ? `Comments: ${val.gaugeTrackingComments}, ` : ''),
    needType: (val) => DeliveryMatrixNeedTypeDisplay.get(val)
};
export const changelogValueDisplayMapper = (key, value) => {
    if (changeLogValueDisplayOverrides[key]) {
        return changeLogValueDisplayOverrides[key](value);
    }
    else if (key.endsWith('Date')) {
        return dateDisplay(value);
    }
    else {
        return value;
    }
};
