var _a;
import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ChangeSetStatus, InventoryControl, ItemMasterType, Maybe } from '~/db_types';
import { ItemMasterPropertyClass } from '~/db_types/swagger_types';
import BaseSearch, { srFilterOutSelected } from '~/nasa_ui/base/BaseSearch';
import { AS_BUILT_COL, BASE_ICON_COL, BASE_TEXT_COL, DESCRIPTION_COL, DRAWING_NUMBER_COL, INITIALIZATION_STATUS_ICON_COL, SIDE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import HttpMixin from '~/nasa_ui/mixins/HttpMixin';
import { EntityType } from '~/nasa_ui/types';
import { ChangeSetStatusDisplay } from '~/nasa_ui/types/enums/change-set';
import { EquipmentGroupDisplay } from '~/nasa_ui/types/enums/equipment-group';
import { InventoryControlDisplay, ItemMasterPropertyClassDisplay } from '~/nasa_ui/types/enums/hardware';
import { isNullOrUndefined } from '~/nasa_ui/utils';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
import { transformEntitiesToTableFormat } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let SearchItemMaster = class SearchItemMaster extends Mixins(BaseSearch, HttpMixin) {
    ChangeSetStatus = ChangeSetStatus;
    ChangeSetStatusDisplay = ChangeSetStatusDisplay;
    entityType = EntityType.ITEM_MASTER;
    EquipmentGroupDisplay = EquipmentGroupDisplay;
    hint = 'Drawing number, Description';
    inInventoryLocal = true;
    InventoryControl = InventoryControl;
    InventoryControlDisplay = InventoryControlDisplay;
    ItemMasterType = ItemMasterType;
    placeholder = 'search item masters';
    ItemMasterPropertyClass = ItemMasterPropertyClass;
    ItemMasterPropertyClassDisplay = ItemMasterPropertyClassDisplay;
    userSelectedMaxResults = 1000;
    searchFilters = {
        asBuiltNumber: null,
        drawingNumber: null,
        initializationStatus: null,
        inventoryControl: null,
        isApprovedAsDesign: null,
        propertyClass: null,
        subType: null
    };
    yesNoOptions = [
        {
            displayText: 'Yes',
            value: true
        },
        {
            displayText: 'No',
            value: false
        }
    ];
    existingItemMasters;
    inInventory;
    tableHeaders = [
        composeTableHeader(DRAWING_NUMBER_COL, { width: '275px' }),
        AS_BUILT_COL,
        SIDE_COL,
        composeTableHeader(BASE_ICON_COL, {
            text: 'Approved AD',
            noEscape: true,
            value: '_approvalStatusIcon'
        }),
        INITIALIZATION_STATUS_ICON_COL,
        composeTableHeader(DESCRIPTION_COL, { width: '350px' }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Federal Supply<br/>Class',
            value: '_federalSupplyClass',
            sortable: true,
            width: '150px'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Equipment<br/>group',
            value: '_subType',
            sortable: true,
            noWrap: true
        })
    ];
    get chipsFromFilters() {
        if (!this.pureSearchFilters || Object.keys(this.pureSearchFilters).length === 0) {
            return [];
        }
        let appChips = [];
        appChips = appChips.concat(this.buildAppChipForProperty('drawingNumber', 'item_drawings'), this.buildAppChipForProperty('asBuiltNumber', 'item_masters'), this.buildAppChipForProperty('propertyClass', 'item_masters', ItemMasterPropertyClassDisplay), this.buildAppChipForProperty('subType', 'item_masters', EquipmentGroupDisplay), this.buildAppChipForProperty('inventoryControl', 'item_masters', InventoryControlDisplay), this.buildAppChipForProperty('itemMasterType', 'item_masters', EquipmentGroupDisplay), this.buildAppChipForProperty('initializationStatus', 'change_sets', ChangeSetStatusDisplay));
        if (this.inInventoryLocal === true) {
            appChips.push({
                text: 'Inventory Only',
                color: this.entityType,
                value: 'isInventory',
                outline: true,
                close: false // cant really put the 'x' here bc it doesnt tie DIRECTLY to searchFilters (it can be done in other ways)
            });
        }
        if (!isNullOrUndefined(this.searchFilters.isApprovedAsDesign)) {
            appChips.push({
                text: this.searchFilters.isApprovedAsDesign ? 'Only Approved AD' : 'Not Approved AD',
                color: this.entityType,
                value: 'isApprovedAsDesign',
                outline: true,
                close: false
            });
        }
        return appChips.filter((key) => key !== undefined);
    }
    get transformedSearchResponse() {
        if (!this.rawSearchResponse) {
            return [];
        }
        const transformed = transformEntitiesToTableFormat(EntityType.ITEM_MASTER, [...this.rawSearchResponse]);
        transformed.sort(sortObjectNumericallyBy('_updatedDateTimeTicks', true));
        return transformed;
    }
    addSelectedPropIfSelected(data) {
        if (!data || !this.existingItemMasters) {
            return data || [];
        }
        const existingIms = this.existingItemMasters || [];
        return data.map((itemMaster) => {
            if (!itemMaster.drawingNumber && !itemMaster.asBuiltNumber && !itemMaster.side) {
                return itemMaster;
            }
            itemMaster.__SELECTED = existingIms.some((im) => {
                return (im.drawingNumber === itemMaster.drawingNumber &&
                    im.asBuiltNumber === itemMaster.asBuiltNumber &&
                    im.side === itemMaster.side);
            });
            return itemMaster;
        });
    }
    async performSearch() {
        try {
            this.isLoading = true;
            const params = {
                asBuiltNumber: this.searchFilters.asBuiltNumber ? [this.searchFilters.asBuiltNumber] : undefined,
                drawingNumber: this.searchFilters.drawingNumber ? [this.searchFilters.drawingNumber] : undefined,
                equipmentGroup: this.searchFilters.subType ?? undefined,
                hasManagedQuantity: this.inInventoryLocal || undefined,
                initializationStatus: this.searchFilters.initializationStatus ?? undefined,
                inventoryControl: this.searchFilters.inventoryControl || undefined,
                isApprovedAsDesign: this.searchFilters.isApprovedAsDesign ?? undefined,
                propertyClass: this.searchFilters.propertyClass ?? undefined,
                query: this.searchInput ?? undefined,
                take: isNullOrUndefined(this.userSelectedMaxResults) ? -1 : this.userSelectedMaxResults
            };
            const resp = await this.$http.get('/hardware/item-masters', {
                params
            });
            this.isLoading = false;
            this.rawSearchResponse = resp.data.results ? [...resp.data.results] : [];
            this.emitSearchPerformed();
        }
        catch (err) {
            this.isLoading = false;
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    initializeInInventory() {
        this.inInventoryLocal = this.inInventory;
    }
    /**
     * Adds the property __SELECTED to the results so the searches can filter it out if needed
     */
    onExistingItemMastersChange() {
        if (this.existingItemMasters && this.transformedSearchResponse) {
            let data = [...this.transformedSearchResponse];
            // add __SELECTED and pull up nodes a level
            data = this.addSelectedPropIfSelected(data || []);
            this.$set(this, 'searchResults', data.filter(srFilterOutSelected));
        }
    }
    onExistingValueChange() {
        console.warn('The existingValues prop is unsupported on <SearchItemMaster>. Please use existingItemMasters instead.');
    }
};
__decorate([
    Prop({
        type: Array,
        required: false
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], SearchItemMaster.prototype, "existingItemMasters", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], SearchItemMaster.prototype, "inInventory", void 0);
__decorate([
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SearchItemMaster.prototype, "performSearch", null);
__decorate([
    Watch('inInventory', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SearchItemMaster.prototype, "initializeInInventory", null);
__decorate([
    Watch('existingItemMasters', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SearchItemMaster.prototype, "onExistingItemMastersChange", null);
__decorate([
    Watch('existingValues', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SearchItemMaster.prototype, "onExistingValueChange", null);
SearchItemMaster = __decorate([
    Component
], SearchItemMaster);
export default SearchItemMaster;
